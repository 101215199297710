import ZtAddress               from './ZtAddress.vue'
import ZtCheckbox              from './ZtCheckbox.vue'
import ZtCountry               from './ZtCountry.vue'
import ZtCountryAdvance        from './ZtCountryAdvance/index.vue'
import ZtCountryExtension      from './ZtCountryExtension.vue'
import ZtCountrySingle         from './ZtCountrySingle.vue'
import ZtCustomDatePicker      from './ZtCustomDatePicker.vue'
import ZtEmail                 from './ZtEmail.vue'
import ZtInput                 from './ZtInput.vue'
import ZtNumber                from './ZtNumber.vue'
import ZtPastEvents            from './ZtPastEvents.vue'
import ZtPCA                   from './ZtPCA.vue'
import ZtPCACombineUploadImg   from './ZtPCACombineUploadImg.vue'
import ZtPhone                 from './ZtPhone.vue'
import ZtRadio                 from './ZtRadio.vue'
import ZtRadioCombineInput     from './ZtRadioCombineInput.vue'
import ZtRadioCombineUploadImg from './ZtRadioCombineUploadImg.vue'
import ZtRadioCombineUrl       from './ZtRadioCombineUrl.vue'
import ZtScoreVerification     from './ZtScoreVerification.vue'
import ZtTextarea              from './ZtTextarea.vue'
import ZtUploadImage           from './ZtUploadImage.vue'
import ZtUrl                   from './ZtUrl.vue'

export default {
  components: {
    ZtAddress,
    ZtCheckbox,
    ZtCountry,
    ZtCountryAdvance,
    ZtCountryExtension,
    ZtCountrySingle,
    ZtCustomDatePicker,
    ZtEmail,
    ZtInput,
    ZtNumber,
    ZtPastEvents,
    ZtPCA,
    ZtPCACombineUploadImg,
    ZtPhone,
    ZtRadio,
    ZtRadioCombineInput,
    ZtRadioCombineUploadImg,
    ZtRadioCombineUrl,
    ZtScoreVerification,
    ZtTextarea,
    ZtUploadImage,
    ZtUrl,
  },
}
