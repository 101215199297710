<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>
    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ computedRadio }}</div>
    <!--    <c-pre>{{ widget }}</c-pre>-->
  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin from './component-mixin'
import {isEmpty}      from 'lodash'

export default {
  extends   : ComponentMixin,
  mixins    : [],
  components: {},
  name      : 'ZtPhone',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {}
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    computedRadio() {
      let find = this.widget.optionList.find(item => {
        return item.optionValue == this.widget.answer
      })
      if (isEmpty(find)) {
        return ''
      }
      else {
        return find.optionName
      }
      // let optionListElement = this.widget.optionList[this.widget.answer]
      // if (isEmpty(optionListElement)) {
      //   return ''
      // }
      // else {
      //   return optionListElement.optionName
      // }
    },
  },
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "component.scss";
</style>
